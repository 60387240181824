import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { Link } from 'react-router-dom';

// import local components
import AuditsPieChart from './auditsPieChart';
import HorizontalBarChart from './auditsHorizontalBarChart';
import ChainBarChart from './auditsChainBarChart';
import AuditsStackedBarChart from './auditsStackedBarChart';
import Modal from './startAuditModal/standardAudit/standardAuditModal';
import RecentRecurringAudits from './recentRecurringAudits/recentRecurringAudits';
import AdvancedAuditModal from './startAuditModal/advancedAudit/advancedAuditModal';
import ViewAndDownloadModal from 'app/modules/Audits/viewAndDownloadModal';
import DeleteAuditConfirmation from './deleteAuditConfirmation/deleteAuditConfirmation';
import RerunModal from './auditRerunModal/auditRerunModal';
import Filter from './chartMetricFilter';
import AuditResultsSummary from './AuditResultsSummary/AuditResultsSummary';
// Material UI imports
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
// import style sheets
import './audits.scss';
import './auditChartFilters.scss';
import Select from 'react-select';

const Audits = () => {
  const [summaryData, setSummaryData] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [rerunModalActive, setRerunModalActive] = useState(false);
  const [advancedAuditModalStatus, setAdvancedAuditModalStatus] = useState(false);
  const [deleteAuditConfirmationModalStatus, setDeleteAuditConfirmationModalStatus] = useState(false);
  const [numberOfAudits, setNumberOfAudits] = useState(0);
  const [totalHotelsLoaded, setTotalHotelsLoaded] = useState(0);
  const [hotelsSuccessfullyLoaded, setHotelsSuccessfullyLoaded] = useState(0);
  const [allAuditsData, setAllAuditsData] = useState(null);
  const [paginatedRecentRecurring, setPaginatedRecentRecurring] = useState(null);
  const [recentRecurringPage, setRecentRecurringPage] = useState(0);
  const [currentID, setCurrentID] = useState(null);
  const [overRideName, setOverRideName] = useState(null);
  const [currentAuditID, setCurrentAuditID] = useState(null);
  const [viewAndDownloadModalStatus, setViewAndDownloadModalStatus] = useState(false);
  const [individualAuditDownloadStatus, setIndividualAuditDownloadStatus] = useState(false);
  const [recentRecurringAuditDownloadStatus, setRecentRecurringAuditDownloadStatus] = useState(false);
  const [auditResultSummaryDownloadStatus, setAuditResultSummaryDownloadStatus] = useState(false);
  const [hotelPropertiesDownloadStatus, setHotelPropertiesDownloadStatus] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [viewAndDownloadAuditScheduleType, setViewAndDownloadAuditScheduleType] = useState(null);
  const [auditScheduleTypeName, setAuditScheduleTypeName] = useState(null);
  const [lastRunDate, setLastRunDate] = useState(null);
  const [currentAuditRunStatus, setCurrentAuditRunStatus] = useState(false);
  const [deleteAuditID, setDeleteAuditID] = useState(null);
  const [filterMetric, setFilterMetric] = useState('');

  // THIS IS WHAT I ADDED WHICH MIGHT NEED TO BE DELETED

  const handleValueFromChild = (value) => {
    setFilterMetric(value);
  };


  // END OF WHAT I ADDED

  useEffect(() => {
    dataLoad();
  }, []);

  const dataLoad = async () => {
    // Added error handling to catch bubbled up errors from req 
    try{
      await fetchData();
      await loadAllAudits(0);
      await checkErrors();
    } catch (error) { 

    // eslint-disable-next-line no-console
    console.log('Error in dataLoad:', error);
    }
   
  };

  // useEffect(() => {
  //   checkAuditRunStatus(currentID);
  // }, [allAuditsData]);
  //
  // useEffect(() => {
  //   fetchData();
  // }, [currentID]);

  //
  // useEffect(() => {
  //   loadAllAudits(0);
  //   setCurrentID(allAuditsData[0].id);
  //   setRecentRecurringPage(0);
  // }, [uploading]);

  // FIXME : this is not running and leaving behind data (see if we can get rid of this one)
  // useEffect(() => {
  //   setErrorData(null);
  //   setSummaryData(null);
  //   setCurrentID(null);
  // }, [numberOfAudits]);

  const fetchData = async () => {
    const auditData = (await axios.get('/api/user/audit')).data;

    if (auditData._embedded) {
      const id = auditData._embedded.auditResultsSummaryList[0].id;
      const auditScheduleType = auditData._embedded.auditResultsSummaryList[0].audit.auditScheduleTypeName.description;
      const runComplete = auditData._embedded.auditResultsSummaryList[0].runComplete;

      passScheduleType(auditData._embedded.auditResultsSummaryList[0].audit.auditScheduleTypeName.description);
      await setResultCounts(id);
      setCurrentID(id);
      setCurrentAuditRunStatus(auditData._embedded.auditResultsSummaryList[0].runComplete);
      setNumberOfAudits(auditData._embedded.auditResultsSummaryList.length);
      if (auditScheduleType !== 'Recurring' && !runComplete) {
        setUploading(true);
      }
    } else {
      setCurrentID(null);
      setCurrentAuditRunStatus(false);
      setNumberOfAudits(0);
      setUploading(false);
    }
  };

  const setResultCounts = async id => {
  
    if(!id){ 
      setSummaryData(null); 
      setTotalHotelsLoaded(0); 
      setHotelsSuccessfullyLoaded(0); 

    } else if (id) {

    const singleAuditSummary =  axios.get('/api/user/audit/' + id + '/summary');
    const singleAuditErrors =  axios.get('/api/user/audit/' + id + '/errors');

    const [singleAuditErrorsResult, singleAuditResult] = await Promise.allSettled([singleAuditErrors, singleAuditSummary]); 

    // Error handling for singleAuditSummary
    // With current error handling the data will update oddly and not alert user 
    if (singleAuditResult.status === "rejected") { 
      setSummaryData(null); 
      setTotalHotelsLoaded(0); 
      setHotelsSuccessfullyLoaded(0); 
    } else if ( singleAuditResult.status === "fulfilled"){
      setSummaryData(singleAuditResult.value.data);
      setTotalHotelsLoaded(singleAuditResult.value.data.totalProcessed);
      setHotelsSuccessfullyLoaded( singleAuditResult.value.data.success); 
   }

   // Error handling for singleAuditErrors 
   if(singleAuditErrorsResult.status === "rejected"){ 
    setErrorData(null);
  } else if ( singleAuditErrorsResult.status === "fulfilled") {
    setErrorData(singleAuditErrorsResult.value.data);
    
 }
    
}};

  const loadAllAudits = async updatedPage => {
    const paginatedRecentRecurringDataLoading = (await axios.get('/api/user/audit/recent?page=' + updatedPage + '&size=4')).data;
    setRecentRecurringPage(updatedPage);
    const allAuditsDataGet = (await axios.get('/api/user/audit')).data;

    if (allAuditsDataGet._embedded) {
      const data = allAuditsDataGet._embedded.auditResultsSummaryList;
      setAllAuditsData(data);
      setCurrentAuditRunStatus(data[0].runComplete);

      if (data[0].runComplete && data[0].audit.auditScheduleTypeName.description === 'One-Time') {
        setAuditScheduleTypeName(data[0].audit.auditScheduleTypeName.description);
      } else {
        setAuditScheduleTypeName(data[0].audit.auditScheduleTypeName.description);
      }
    } else {
      setAllAuditsData(null);
    }
    setPaginatedRecentRecurring(paginatedRecentRecurringDataLoading);
  };

  const checkErrors = async () => {
    let allAuditsList = null;
    await axios
      .get('/api/user/audit')
      .then(response => {
        allAuditsList = response.data;
        if (allAuditsList._embedded) {
          setAllAuditsData(allAuditsList._embedded.auditResultsSummaryList);
          allAuditsList = allAuditsList._embedded.auditResultsSummaryList;
        } else {
          setAllAuditsData(null);
        }
      })
      .then(async () => {
        let singleAuditData = null;
        if (allAuditsList[0] && allAuditsList[0].audit.auditScheduleTypeName.description !== 'Recurring') {
          singleAuditData = allAuditsList[0];
          const percentData = (await axios.get(`${singleAuditData._links.summary.href}`)).data;
          const percent = percentData.percentProcessed;
          if (percent === 1 && singleAuditData.runComplete) {
            await fetchData();
            setUploading(false);
            setCurrentAuditRunStatus(true);
            await loadAllAudits(0);
          } else {
            setTimeout(() => {
              checkErrors();
            }, 3000);
          }
        } else {
          setUploading(false);
        }
      });
  };

  const updateID = async () => {
    const auditData = (await axios.get('/api/user/audit')).data;

    if (auditData._embedded) {
      const id = auditData._embedded.auditResultsSummaryList[0].id;
      setCurrentID(id);
    } else {
      setCurrentID(null);
    }
  };

  const setAuditID = id => {
    setCurrentAuditID(id);
  };

  // Event handlers

  const handleRecentRecurringAuditChange = async (event, value) => {
    await loadAllAudits(value - 1);
  };

  const checkAuditRunStatus = id => {
    let matchCheck = false;
    if (allAuditsData) {
      allAuditsData.map(data => {
        const returnedID = parseInt(id, 10);
        if (data.id === returnedID) {
          matchCheck = data.runComplete;
          passScheduleType(data.audit.auditScheduleTypeName.description);
        }
      });
    } else {
      setCurrentAuditRunStatus(false);
    }
    setCurrentAuditRunStatus(matchCheck);
  };

  const handleDropdownChange = async event => {
    checkAuditRunStatus(event.value);
    setCurrentID(event.value);
 
    // await fetchData();
    await setResultCounts(event.value);
  };

  const triggerCheckErrors = async () => {
    await loadAllAudits(0);
    await updateID();
    await checkErrors();
  };

  const toggleModalStatus = () => {
    setModalActive(!modalActive);
  };

  const toggleAdvancedAuditModalStatus = async () => {
    setAdvancedAuditModalStatus(!advancedAuditModalStatus);
    await fetchData();
    await loadAllAudits(0);
  };

  const toggleRerunModalStatus = () => {
    setRerunModalActive(!rerunModalActive);
  };

  const toggleViewAndDownloadModalStatus = () => {
    setViewAndDownloadModalStatus(!viewAndDownloadModalStatus);
  };

  const toggleDeleteAuditConfirmationModalStatus = () => {
    setDeleteAuditConfirmationModalStatus(!deleteAuditConfirmationModalStatus);
  };

  const passViewAndDownloadScheduleType = scheduleType => {
    if (scheduleType === 'Recurring') {
      setViewAndDownloadAuditScheduleType(scheduleType);
    } else {
      setViewAndDownloadAuditScheduleType(null);
    }
  };

  const passScheduleType = scheduleType => {
    // console.log(scheduleType);
    // if (scheduleType === 'Recurring') {
    setAuditScheduleTypeName(scheduleType);
    // } else {
    //   setAuditScheduleTypeName(null);
    // }
  };

  const passLastRunDate = lastRunDateArg => {
    setLastRunDate(lastRunDateArg);
  };

  const passAuditID = id => {
    let match = false;

    for (let i = 0; match === false; i++) {
      if (allAuditsData[i].audit.id === id) {
        setCurrentAuditID(allAuditsData[i].id);
        match = true;
      }
    }
  };

  const passDeleteAuditID = id => {
    setDeleteAuditID(id);
  };

  const passAuditName = id => {
    let match = false;

    for (let i = 0; match === false; i++) {
      if (allAuditsData[i].audit.id === id) {
        setOverRideName(allAuditsData[i].audit.auditName);
        match = true;
      }
    }
  };

  // returns a list of options for the dropdown list
  const returnOptions = () => {
    const optionData = allAuditsData.map(data => {
      return { value: data.id, label: data.audit.auditName };
    });
    return optionData;
  };

  const returnDropdownValue = () => {
    let dropdownValue = null;
    allAuditsData.map(data => {
      if (data.id === currentID) {
        dropdownValue = { value: data.audit.id, label: data.audit.auditName };
      }
    });

    return dropdownValue;
  };

  const refreshAudits = async () => {
    await fetchData();
    await loadAllAudits(recentRecurringPage);
  };

  const setDownloadStatus = (individual, recentRecurring, auditResult, property) => {
    setIndividualAuditDownloadStatus(individual);
    setRecentRecurringAuditDownloadStatus(recentRecurring);
    setAuditResultSummaryDownloadStatus(auditResult);
    setHotelPropertiesDownloadStatus(property);
  };

  const setUploadStatus = uploadingArg => {
    setUploading(uploadingArg);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.value === currentID ? '#fff' :  '#000',
      padding: 20,
      width: 200,
      height: 40,
      ':hover': {
        backgroundColor: '#56c88c',
        color: '#fff',
      },
      cursor: 'pointer',
      display: 'grid',
      placeContent: 'center',
      backgroundColor: state.value === currentID ? '#1995d2' : '#fff',
      transparent: true,
    }),
    control: (provided, state) => ({
      // ...provided,
      height: '2rem',
      backgroundColor: '#1995d2',
      border: 'none',
      width: 200,
      borderRadius: '5px',
      cursor: 'pointer',
      display: 'flex',
      placeContent: 'space-between',
      alignItems: 'center',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#fff',
      marginTop: '.25rem',
    }),
    dropdownIndicator: (provided, state) => ({
      color: '#fff',
      display: 'grid',
      placeContent: 'center',
      width: '2rem',
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      position: 'absolute',
      marginTop: '.5rem',
      top: 'null',
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: '#1995d2',
      borderRadius: '5px',
      padding: 0,
     
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      backgroundColor: 'yellow',
    }),
    container: (provided, state) => ({
      ...provided,
      marginLeft: '2rem',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#fff',
    }),
    noOptionsMessage: base => ({ ...base, color: '#fff' })
  };

  return (
    <div>
      <div className="Audits">
        <Modal
          active={modalActive}
          updateAudits={() => refreshAudits()}
          toggleModalStatus={() => toggleModalStatus()}
          setUploadStatus={setUploadStatus}
          setAuditID={setAuditID}
          refresh={refreshAudits}
          updateID={updateID}
          triggerCheckErrors={triggerCheckErrors}
          passScheduleType={passScheduleType}
        />
        <AdvancedAuditModal active={advancedAuditModalStatus} toggleAdvancedAuditModalStatus={() => toggleAdvancedAuditModalStatus()} />
        <DeleteAuditConfirmation
          active={deleteAuditConfirmationModalStatus}
          toggleModalStatus={toggleDeleteAuditConfirmationModalStatus}
          auditID={deleteAuditID}
          overRideName={overRideName}
          updateID={updateID}
          refreshAudits={() => refreshAudits()}
          currentAuditID={allAuditsData ? allAuditsData[0].audit.id : null}
          passUploadStatus={setUploadStatus}
        />
        <ViewAndDownloadModal
          active={viewAndDownloadModalStatus}
          toggleModal={() => toggleViewAndDownloadModalStatus()}
          id={currentAuditID}
          individualAuditDownloadStatus={individualAuditDownloadStatus}
          recentRecurringAuditDownloadStatus={recentRecurringAuditDownloadStatus}
          auditResultSummaryDownloadStatus={auditResultSummaryDownloadStatus}
          hotelPropertiesDownloadStatus={hotelPropertiesDownloadStatus}
          currentID={currentID}
          scheduleType={viewAndDownloadAuditScheduleType}
          lastRunDate={lastRunDate}
          uploadingStatus={uploading}
        />
        <RerunModal
          active={rerunModalActive}
          updateAudits={() => refreshAudits()}
          toggleModalStatus={() => toggleRerunModalStatus()}
          setUploadStatus={setUploadStatus}
          setAuditID={setAuditID}
          refresh={refreshAudits}
          updateID={updateID}
          triggerCheckErrors={triggerCheckErrors}
          passScheduleType={passScheduleType}
        />

        <h1 className="auditsTitle">HOTEL RATE AUDIT</h1>
         
        <div className="container">
            <div className="recentRecurringAuditsTitleContainer">
              <h2 className="recentAndRecurringAuditsTitle ">Recent and Recurring Audits</h2>
              <div className="buttonGroup">
                {uploading ? (
                  <div className="loadingBox">
                    <h1 className="auditInProgress"> Audit in Progress </h1>
                    <CircularProgress className="circularProgress" />
                  </div>
                ) : (
                  <button onClick={() => toggleModalStatus()} className="button button-filled">
                      <p className="button-text">Run an Audit</p>
                  </button>
                )}

                <Link className="button button-outline" to="/propertiesneedingaction">
                  <p className="button-text">Manage Rate Load Errors</p>
                </Link>
              </div>
            </div>

            <div className="recentAndRecurringAudits">
              <div className="recentList">
                <div className="rRAudits">
                  <div className="recentColumnHeadings">
                    <div className="auditTypeRR recentColumnHeading">
                      <p>AUDIT TYPE</p>
                    </div>
                    <div className="auditNameRR recentColumnHeading">
                      <p>AUDIT NAME</p>
                    </div>
                    <div className="frequencyRR recentColumnHeading">
                      <p>FREQUENCY</p>
                    </div>
                    <div className="lastRunDateRR recentColumnHeading">
                      <p>LAST RUN DATE</p>
                    </div>
                    <div className="nextRunDateRR recentColumnHeading">
                      <p>NEXT RUN DATE</p>
                    </div>
                    <div className="auditRunStatusRR recentColumnHeading">
                      <p>STATUS</p>
                    </div>
                    <div className="actionsRR recentColumnHeading">
                      <p>ACTIONS</p>
                    </div>
                  </div>
                </div>
                {paginatedRecentRecurring ? (
                  paginatedRecentRecurring.page.totalElements !== 0 ? (
                    <div className="recentRecuringPagination">
                      <RecentRecurringAudits
                        rRAuditData={paginatedRecentRecurring}
                        refreshAudits={() => refreshAudits()}
                        passAuditID={passAuditID}
                        passDeleteAuditID={passDeleteAuditID}
                        passAuditName={passAuditName}
                        passScheduleType={passViewAndDownloadScheduleType}
                        toggleViewAndDownloadModal={toggleViewAndDownloadModalStatus}
                        setDownloadStatus={setDownloadStatus}
                        updateID={updateID}
                        passLastRunDate={passLastRunDate}
                        toggleDeleteAuditModalStatus={toggleDeleteAuditConfirmationModalStatus}
                        toggleRerunAuditModal={toggleRerunModalStatus}
                      />
                    </div>
                  ) : (
                    <div className="noAuditsText">
                      <h1>No audits to display yet? No problem! Get the most out of your travel budget and start an audit now.</h1>
                    </div>
                  )
                ) : (
                  <div className="noAuditsText">
                    <CircularProgress />
                  </div>
                )}
              </div>
              <div className="buttonDiv recentRecurringButton">
                <Pagination // FIXME
                  count={paginatedRecentRecurring ? paginatedRecentRecurring.page.totalPages : 0}
                  variant="outlined"
                  shape="rounded"
                  className="recentRecurringPaginationComponent"
                  onChange={handleRecentRecurringAuditChange.bind(this)}
                  defaultPage={1}
                  page={recentRecurringPage + 1}
                />

                <button
                  className="downloadButton"
                  onClick={() => {
                    setDownloadStatus(false, true, false, false);
                    toggleViewAndDownloadModalStatus();
                  }}
                >
                  Download Full Report
                </button>
              </div>
            </div>
        </div>
        <div className="resultsTitle">
          <h3>Audit Results Summary</h3>
          <div className="selectAuditLabelContainer">
            <h5>SELECT AN AUDIT:</h5>
          </div>
          {allAuditsData ? (
            <Select
              value={returnDropdownValue()}
              defaultValue={{ value: allAuditsData[0].audit.id, label: allAuditsData[0].audit.auditName }}
              styles={customStyles}
              options={returnOptions()}
              onChange={handleDropdownChange.bind(this)}
            />
          ) : (
            <Select
            defaultValue={{ value: 0 }}
            styles={customStyles}
          />
          )}
        </div>
        <div className="auditResults">
          <AuditResultsSummary
            currentAuditRunStatus={currentAuditRunStatus}
            summaryData={summaryData}
          />
          <div className="filterOverviewSpacing">
            <div className="filterOverview">
              <div className="filterTitle">
                <h5>Select Metric:</h5>
              </div>
              <div className="filterSection">
                <Filter onSendValue={handleValueFromChild} />
              </div>
            </div>
          </div>

          
          {allAuditsData ? (
            currentAuditRunStatus ? (
              <div className="graphContainer">
                <div className="leftSideContainer">
                  <div className="auditsStackedBarChartContainer container">
                    <div className="auditsStackedTitle">
                      <p className="resultsByChain">Performance by Chain</p>
                    </div>
                    {currentID ? (
                      <div className="auditsStackedBarChartContent">
                        {/* <div className="stackBarChartLegend">
                          <div className="totalCircleStackLegend"></div>
                          <p className="totalStackText">Total</p>
                          <div className="passCircleStackLegend"></div>
                          <p className="passStackText">Pass</p>
                          <div className="failCircleStackLegend"></div>
                          <p className="failStackText">Fail</p>
                        </div> */}
                        <ChainBarChart auditID={currentID} selectedMetric={filterMetric} numberOfErrors={errorData ? errorData.page.totalElements : 0} />
                      </div>
                    ) : (
                      <div className="auditsStackedBarChartContent">
                        <div className="stackedBarChartPlaceholder"></div>
                      </div>
                    )}
                  </div>

                  <div className="auditsPieChartContainer container">
                    <div className="auditsPieChartTitle">
                      <p className="failureTypes">Failure Types</p>
                    </div>
                    {errorData && errorData._embedded ? (
                      <AuditsPieChart auditID={currentID} failureDataLength={summaryData ? summaryData.totalFailures : 0} />
                    ) : hotelsSuccessfullyLoaded ? (
                      <div className="auditsPieChartContent">
                        <img className="successGraphic" src="../../../content/images/successGraphic.png" alt="" />
                      </div>
                    ) : (
                      <div className="auditsPieChartContent">
                        <div className="pieChartPlaceholder"></div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="rightSideContainer">
                  <div className="auditsHorizontalBarChartContainer container">
                    <div className="auditsHorizontalTitle">
                      <p className="resultsByMarket">Performance by Market</p>
                    </div>


                    {currentID ? (
                      <div className="auditsHorizontalBarChartContent">
                        {/* <div className="horizontalBarChartLegend">
                          <div className="passCircleHorizontalLegend"></div>
                          <p className="passText">Pass</p>
                          <div className="failCircleHorizontalLegend"></div>
                          <p className="failText">Fail</p>
                        </div> */}
                        <HorizontalBarChart auditID={currentID} selectedMetric={filterMetric} numberOfErrors={errorData ? errorData.page.totalElements : 0} />
                      </div>
                    ) : (
                      <div className="auditsHorizontalBarChartContent">
                        <div className="horizontalBarChartPlaceholder"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : auditScheduleTypeName === 'One-Time' ? (
              <div className="pendingAuditResultsMessage">
                <h3>An audit is in progress! Results will display after the audit run is complete.</h3>
              </div>
            ) : (
              <div className="pendingAuditResultsMessage">
                <h3>Kudos on scheduling an audit! Results will display after the scheduled audit run is complete.</h3>
              </div>
            )
          ) : (
            <div className="pendingAuditResultsMessage">
              <h3>Let&apos;s start auditing your hotel program and find potential cost savings!</h3>
              <h3>
                Click{' '}
                <button className="hyperlinkButton" onClick={() => toggleModalStatus()}>
                  <h3>here</h3>
                </button>{' '}
                to start an audit!
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Audits;

